import { ApolloClient, from, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { isProd, isBrowser } from '~/utils/env';
import { logLink, persistentQueryLink, getAuthToken } from './core';
import CustomSubscriptionClient from './CustomSubscriptionClient';
import possibleTypes from '../../../possibleTypes.json';

let apolloClient = null;

export const CLIENT_REALTY_AD = 'realty-ad';
export const CLIENT_REALTY_SERVICES = 'realty-services';

function create(initialState) {
    // function create(initialState, { getToken }) {
    // function create(initialState) {

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (networkError) console.log(`[Network error]: ${networkError}`);

        if (graphQLErrors) {
            graphQLErrors.forEach(({ extensions, message, locations, path }) => {
                if (!isProd) {
                    console.log(
                        `[GraphQL error]: Message: ${message}, Code: ${
                            extensions ? extensions.code : ''
                        }, Location: ${locations}, Path: ${path}`
                    );
                }
                if (extensions && extensions.code === 'UNAUTHENTICATED') {
                    // TODO jessie
                    // store.dispatch(logoutUser());
                    // https://gist.github.com/alfonmga/9602085094651c03cd2e270da9b2e3f7
                }
            });
        }
    });

    const realtyLink = from([
        logLink,
        errorLink,
        persistentQueryLink,
        setContext((_, { headers }) => {
            const token = getAuthToken();
            return {
                headers: {
                    ...headers,
                    ...(token ? { authorization: `Bearer ${token}` } : {}),
                },
            };
        }),
        createUploadLink({
            uri: process.env.NEXT_PUBLIC_CLIENT_API,
            // Disable for now
            // useGETForQueries: true,
            credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        }),
    ]);

    const realtyAdLink = from([
        logLink,
        errorLink,
        setContext((_, { headers }) => ({
            headers: {
                ...headers,
                authorization: `Bearer ${process.env.NEXT_PUBLIC_REALTYADS_AUTH}`,
            },
        })),
        createUploadLink({ uri: process.env.NEXT_PUBLIC_REALTYADS_API }),
    ]);

    const servicesApiLink = from([
        logLink,
        errorLink,
        setContext((_, { headers }) => ({
            headers: {
                ...headers,
                authorization: `Bearer ${process.env.NEXT_PUBLIC_SERVICES_API_AUTH}`,
            },
        })),
        createUploadLink({ uri: process.env.NEXT_PUBLIC_SERVICES_API }),
    ]);

    // split based on operation type
    const splitFn =
        (hasWs = false) =>
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);

            return hasWs
                ? kind === 'OperationDefinition' && operation === 'subscription'
                : kind === 'OperationDefinition';
        };

    const splitRealtyAdsClient = (operation) => {
        const { clientName } = operation.getContext();
        return clientName === CLIENT_REALTY_AD;
    };

    const splitServicesClient = (operation) => {
        const { clientName } = operation.getContext();
        return clientName === CLIENT_REALTY_SERVICES;
    };

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    // let link = split(splitClient, realtyAdLink, realtyLink);
    let link = split(splitServicesClient, servicesApiLink, split(splitRealtyAdsClient, realtyAdLink, realtyLink));

    if (isBrowser && window.WebSocket) {
        const token = getAuthToken();

        const client = new CustomSubscriptionClient(process.env.NEXT_PUBLIC_CLIENT_SUBSCRIPTION_URI, {
            reconnect: true,
            connectionParams: { ...(token ? { authorization: `Bearer ${token}` } : {}) },
        });

        const wsLink = new WebSocketLink(client);

        link = split(
            splitServicesClient,
            servicesApiLink,
            split(splitRealtyAdsClient, realtyAdLink, split(splitFn(true), wsLink, realtyLink))
        );
    }

    return new ApolloClient({
        assumeImmutableResults: true,
        connectToDevTools: isBrowser,
        ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
        link,
        name: process.env.NEXT_PUBLIC_APP_CLIENT_NAME,
        version: process.env.NEXT_PUBLIC_APP_CLIENT_VERSION,
        // ssrForceFetchDelay: 1,
        cache: new InMemoryCache({
            addTypename: true,
            possibleTypes,
        }).restore(initialState || {}),
    });
}

export default function initApollo(initialState, options) {
    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)

    // if(!isBrowser){
    //     console.log('initApollo - !Browser');
    // } else{
    //     console.log('initApollo Browser');
    // }

    if (!isBrowser) return create(initialState, { ...options });

    // Reuse client on the client-side
    if (!apolloClient) apolloClient = create(initialState, { ...options });

    return apolloClient;
}
